const region = "eu-central-1"

const local = {
	Auth: {
		mandatorySignIn: true,
		region: region,
		userPoolId: "eu-central-1_ozznPM8h2",
		identityPoolId:"eu-central-1:f030344b-15ec-4856-a1d5-c00f565b5aa6",
		userPoolWebClientId: "4t7u7m7otn5thos8ogsj8869hq",
		oauth: {
			domain: "dev-brouwsite.auth.eu-central-1.amazoncognito.com",
			scope : ['email', 'openid','profile'],
			redirectSignIn: "http://localhost:3000/",
			redirectSignOut: "http://localhost:3000/",
			responseType: "token"
		}
	},
	API: {
		endpoints: [
			{
				name: "beer",
				endpoint: "https://anesc4g1fj.execute-api.eu-central-1.amazonaws.com/dev",
				region: region
			},
			{
				name: "log",
				endpoint: "https://uotqnncco4.execute-api.eu-central-1.amazonaws.com/dev",
				region: region
			}
		]
	}
};

const dev = {
	Auth: {
		mandatorySignIn: true,
		region: region,
		userPoolId: "eu-central-1_ozznPM8h2",
		identityPoolId:"eu-central-1:f030344b-15ec-4856-a1d5-c00f565b5aa6",
		userPoolWebClientId: "4t7u7m7otn5thos8ogsj8869hq",
		oauth: {
			domain: "dev-brouwsite.auth.eu-central-1.amazoncognito.com",
			scope : ['email', 'openid','profile'],
			redirectSignIn: "https://develop--brouwsite-aws-frontend.netlify.app/",
			redirectSignOut: "https://develop--brouwsite-aws-frontend.netlify.app/",
			responseType: "token"
		}
	},
	API: {
		endpoints: [
			{
				name: "beer",
				endpoint: "https://anesc4g1fj.execute-api.eu-central-1.amazonaws.com/dev",
				region: region
			},
			{
				name: "log",
				endpoint: "https://uotqnncco4.execute-api.eu-central-1.amazonaws.com/dev",
				region: region
			}
		]
	}
};

const prod = {
	Auth: {
		mandatorySignIn: true,
		region: region,
		userPoolId: "eu-central-1_iLUZEoBJS",
		identityPoolId: "eu-central-1:5ac51679-49d0-45dd-b8f8-5abbc6dc72de",
		userPoolWebClientId: "4mghd4b74b7ohqj8f3jpvn4ver",
		oauth: {
			domain: "prod-brouwsite.auth.eu-central-1.amazoncognito.com",
			scope : ['email', 'openid','profile'],
			redirectSignIn: "https://www.demachinebier.nl/",
			redirectSignOut: "https://www.demachinebier.nl/",
			responseType: "token"
		}
	},
	API: {
		endpoints: [
			{
				name: "beer",
				endpoint: "https://kjy4or4q3j.execute-api.eu-central-1.amazonaws.com/prod",
				region: region
			},
			{
				name: "log",
				endpoint: "https://ixyxtzeky1.execute-api.eu-central-1.amazonaws.com/prod",
				region: region
			}
		]
	}
};

// Default to local if not set
let config = {};
switch(process.env.REACT_APP_STAGE) {
	case "prod":
		config = prod;
	  	break;
	case "dev":
		config = dev;
	  	break;
	default:
		config = local;
}
export default config;