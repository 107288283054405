import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AppliedRoute from "./AppliedRoute";
import Login from "../containers/Login";
import NotFound from "../containers/NotFound";
import UnauthenticatedRoute from "./UnauthenticatedRoute";
import AuthenticatedRoute from "./AuthenticatedRoute";
// import BreweryRoute from "./BreweryRoute";
import BeerList from "../containers/BeerList";
import BeerView from "../containers/BeerView";
import History from "../containers/History";
import Log from "../containers/Log/Log";
import Home from "../containers/Home";

export default function Routes({ appProps }) {
	return (
		<Switch>
			<AppliedRoute path="/" exact component={Home} appProps={appProps} />
			<UnauthenticatedRoute
				path="/code"
				exact
			>
				<Redirect to="/"/>
			</UnauthenticatedRoute>
			<UnauthenticatedRoute
				path="/beers"
				exact
				component={BeerList}
				appProps={appProps}
			/>
			<UnauthenticatedRoute
				path="/beers/:id"
				exact
				component={BeerView}
				appProps={appProps}
			/>
			<UnauthenticatedRoute
				path="/history"
				exact
				component={History}
				appProps={appProps}
			/>
			<AuthenticatedRoute
				path="/log"
				exact
				component={Log}
				appProps={appProps}
			/>
			<UnauthenticatedRoute
				path="/login"
				exact
				component={Login}
				appProps={appProps}
			/>

			{/* Finally, catch all unmatched routes */}
			<Route component={NotFound} />
		</Switch>
	);
}
