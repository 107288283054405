import React, { useState, useEffect, useCallback } from "react";
import "./Log.css";
import Selector from "../../components/Beer/Selector";
import { Modal, Button, Form, InputGroup, Spinner } from "react-bootstrap";
import { LogButtonGroup } from "./Form";
import { options } from "./formOptions";
import LoaderButton from "../../components/LoaderButton";
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import Datetime from 'react-datetime';
import 'moment/locale/nl';
import { BeerCap } from "../../components/Beer/Cap";
import { useDataApi } from "../../libs/hooksLib";

export default function Log({
    beerData,
    selectedBeer,
    setSelectedBeer
}) {    
    const [{data:logData, ...getState}, callApiGet] = useDataApi('log', 'get', null, {});
    const [createState, callApiCreate] = useDataApi('log', 'post', null, {});
    const [deleteState, callApiDelete] = useDataApi('log', 'del', null, {});

    const [showModal, setShowModal] = useState(false);
    const [created, setCreated] = useState(Date.now());
    const [type, setType] = useState(null);
    const [label, setLabel] = useState(null);
    const [value, setValue] = useState(null);
    const [comment, setComment] = useState(null);

    const selectedBeerId = selectedBeer ? selectedBeer.resourceId : null
	useEffect(() => {
		if (selectedBeerId) {	
			callApiGet({ url: `/log/${selectedBeerId}` })			
		}
    }, [selectedBeerId, callApiGet])    
    
    useEffect(() => {
        if (selectedBeerId && (createState.isSucces || createState.isError)) {
            setShowModal(false)
			callApiGet({ url: `/log/${selectedBeerId}` })	
        }
    }, [selectedBeerId, createState, callApiGet])

    useEffect(() => {
        if (selectedBeerId && (deleteState.isSucces || deleteState.isError)) {
			callApiGet({ url: `/log/${selectedBeerId}` })	
        }
    }, [selectedBeerId, deleteState, callApiGet])

    function createLog(event) {       
        event.preventDefault(); 
        const getValue = () => {
            switch(options[type].valueType) {
                case 'number':
                    return value;  
                case 'select':
                case 'cap':
                    return options[type].valueItems[value];  
                default:
                    return null;  
            }
        }

        callApiCreate({
            url: `/log/${selectedBeer.resourceId}`,
            init: { body: {
                created: created,
                type: options[type].type,
                label: options[type].label[label],
                value: getValue(),
                unit: options[type].unit,
                comment: comment
            }}
        })		
    }

    function deleteLog(event, logId) {       
        event.preventDefault(); 
        callApiDelete({ 
            url: `/log/${selectedBeer.resourceId}/${logId}`, 
            state: { 
                element: `delete-${logId}`
            }
        })		
    }

    const formValidate = useCallback(() => {
        return type !== null && label !== null 
            && (options[type].valueType === null || value !== null)            
    }, [type, label, value])

    const columns = [
        {
            name: 'Tijd',
            wrap: true,
            compact: true,
            grow: 2, 
            selector: (row) => {
                let d = new Date(row.created)                
                return d.toLocaleString('sv-SE', {'dateStyle': 'short', 'timeStyle':'short'})
            }
        },
        {
            name: 'Type',
            selector: 'type',
            grow: 0, 
        },
        {
            name: 'Label',
            selector: 'label',
            hide: 'sm',   
            grow: 2,  
        },
        {
            name: 'Waarde',       
            grow: 2,  
            selector: (row) => { 
                return ( row.value ? row.value : "" ) + 
                    ( row.unit ? ` ${row.unit}` : "" )
            },
        },
        {
            name: 'Commentaar',
            selector: 'comment',
            grow: 10,
            hide: 'md',
        },
        {
            button: true,
            right: true,
            width: '20px',
            hide: 'sm',
            cell: (row) => 
            <>
                { ( deleteState.isLoading && deleteState.element === `delete-${row.id}` ) && (
                    <div className="deleteSpinnerContainer">
                        <Spinner 
                            as="span"
                            animation="border"
                            size="sm"
                        />
                    </div>
                )}
                <FontAwesomeIcon 
                    className="text-danger"
                    icon={faTrashAlt} 
                    size="lg"
                    onClick={(event) => deleteLog(event, row.id)}
                />
            </>,
        },
      ];

    const customStyles = {
      rows: {
        style: {
          fontSize: '12px',
          minHeight: '30px', // override the row height
        }
      },
      headCells: {
        style: {
          fontSize: '14px',
          fontWeight: 800,
        },
      },
    };       
    
    // TODO: sort on time
    // TODO: add coloring based on type
    // TODO: add filtering based on type
    const logList = (   
            <DataTable
                className="mt-4"
                keyField="id" 
                columns={columns} 
                data={Object.values(logData)}   
                highlightOnHover
                noDataComponent=''
                customStyles={customStyles}
                noHeader={true}
                progressPending={getState.isLoading} 
                progressComponent={<Spinner animation="border"/>}
            />
    )

    const timeFormGroup = (
        <Form.Group controlId="timeFormGroup">
            <p size="sm">Tijdstip</p>    
                <Datetime                 
                    value={created}   
                    dateFormat="YYYY-MM-DD"
                    locale="nl-NL" 
                    onChange={(e) => {setCreated(e.valueOf())}}   
                />
        </Form.Group>  
    )    

    const typeFormGroup = (
        <Form.Group controlId="typeFormGroup">
            <p size="sm">Soort</p>                
            <LogButtonGroup      
                items={options.map((item) => item.type)} 
                value={type}    
                setValue={(val) => {                    
                    setType(val)
                    setLabel(options[val].label.length === 1 ? 0 : null)
                    setValue(null)
                }}
            />
        </Form.Group>                    
    )

    const labelFormGroup = type !== null && (
        <Form.Group controlId="labelFormGroup">
            <p size="sm">Label</p>     
            <LogButtonGroup    
                items={options[type].label} 
                value={label}   
                setValue={setLabel}
            />  
        </Form.Group>                    
    )

    const valueFormControl = () => {
        switch(options[type].valueType) {
            case 'number':
                return (                        
                    <Form.Control 
                        size="sm"
                        type="number" 
                        value={value ? value : ""}    
                        onChange={(e) => 
                            setValue(parseFloat(e.currentTarget.value))
                        }
                    /> 
                );
                case 'select':   
                    return (              
                        <LogButtonGroup      
                            items={options[type].valueItems} 
                            value={value ? value : ""}    
                            setValue={setValue}
                        /> 
                    )
                case 'cap':   
                    return (              
                        <LogButtonGroup      
                            className="cap-button-group"
                            items={options[type].valueItems} 
                            value={value ? value : ""}    
                            setValue={setValue}
                            renderItem = {(item) => (
                                <BeerCap
                                    color = {item}
                                >
                                </BeerCap>
                            )}
                        /> 
                    )
            default:
                return;  
        } 
    }

    const valueFormGroup =  type !== null && options[type].valueType && (
        <Form.Group controlId="valueFormGroup">
            <Form.Label>Waarde</Form.Label>
            <InputGroup size="sm">    
            { valueFormControl() }
                { options[type].unit && (
                    <InputGroup.Append>
                        <InputGroup.Text>{ options[type].unit} </InputGroup.Text>
                    </InputGroup.Append>
                )}           
            </InputGroup>
        </Form.Group>                    
    )

    const commentFormGroup = type !== null && (
        <Form.Group controlId="commentFormGroup">
            <Form.Label>Opmerking</Form.Label>
            <Form.Control  
                size="sm"
                as="textarea" 
                rows={2} 
                onChange={(e) => 
                    setComment(e.currentTarget.value)
                }
            />
        </Form.Group>                    
    )    

    return (
		<div className="Log">
            {/* TODO: enable alerts */}
            {/* <div className="alertContainer">
                <Alert show={alert.show} variant={alert.variant}>
                    {alert.msg}
                </Alert>    
            </div>  */}
            <h4>Logs</h4>   
            <Selector
                beerData={beerData}
                selectedBeer={selectedBeer}
                setSelectedBeer={setSelectedBeer}
            />
            <Button variant="primary" onClick={() => setShowModal(true)}>
                Toevoegen
            </Button>
            <Modal show={showModal} onHide={() => setShowModal(false)}>                    
				<Form onSubmit={createLog}>
                    <Modal.Header closeButton>
                        <Modal.Title>Log toevoegen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>     
                        { timeFormGroup }
                        { typeFormGroup }
                        { labelFormGroup }
                        { valueFormGroup }
                        { commentFormGroup }
                    </Modal.Body>
                    <Modal.Footer>                        
                        <LoaderButton
                            type="submit"
                            variant="primary"
                            isLoading={createState.isLoading}
                            disabled={!formValidate()}
                        >
                            Ok
                        </LoaderButton>
                    </Modal.Footer>
                </Form>
            </Modal>            
            { logList }
        </div>
    );
}
