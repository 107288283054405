import React, { useContext } from "react";
import { Row, Col, Container, Accordion, Button, useAccordionToggle, Spinner } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import { BeerCap, getCapColors} from '../components/Beer/Cap'
import "./BeerList.css";
import { useHistory } from "react-router-dom";
import { 
    useQueryParam, 
    NumberParam,
    withDefault 
} from 'use-query-params';
import bottleCap from '../images/bottle_cap_with_code.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import AccordionContext from 'react-bootstrap/AccordionContext';

const columns = [
    {
        name: 'Dop',
        selector: 'cap_color',
        width: '80px',
        cell: row => getCapColors(row.cap_color).map(
            (color, index) => <BeerCap key={index} color={color}></BeerCap>
        )
    },
    {
        name: 'Batch',
        selector: 'batch',
        width: '70px',
    },
    {
        name: 'Gebrouwen',
        selector: 'brew_date',
        hide: 'md',
        grow: 2,
    },
    {
        name: 'Naam',
        selector: 'name',
        grow: 3,
        cell: row => row.name || <em>N.N.</em>
    },
    {
        name: 'Stijl',
        selector: 'style',
        grow: 3,
        hide: 'sm'
    }
];

const customStyles = {
    rows: {
        style: {
            fontSize: '14px',
            minHeight: '40px', // override the row height
        }
    },
    headCells: {
        style: {
            fontSize: '16px',
            fontWeight: 800,
        },
    },
}; 

function ContextAwareToggle( { children, eventKey } ) {
    const currentEventKey = useContext(AccordionContext);
    const isCurrentEventKey = currentEventKey === eventKey;  
    const decoratedOnClick = useAccordionToggle( eventKey );

    return (
        <Button
            type="button"
            variant="link"
            onClick={decoratedOnClick}
        >
            {children}
            
            <FontAwesomeIcon 
                icon={isCurrentEventKey ? faCaretUp : faCaretDown}
                size="lg"
            />
        </Button>
    );
}

export default function BeerList({ user, isLoading, beerData }) {  	
    let history = useHistory();
    const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1))
    const [rows, setRows] = useQueryParam('rowsPerPage', withDefault(NumberParam, 10))
    return (
        <div className="BeerList"> 
            <Container>
                <Row>
                    <Col sm>
                        <h2>Onze bieren</h2>            
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col sm="auto">
                        <Accordion defaultActiveKey={user ? null : "0"}>
                            <ContextAwareToggle eventKey="0">	
                                <FontAwesomeIcon 
                                    icon={faInfoCircle}
                                    className="mr-2"
                                    size="lg"
                                />
                                <strong className="mr-auto">Vind je batch nummer op de dop van de fles </strong>
                            </ContextAwareToggle>
                            <Accordion.Collapse eventKey="0">
                                    <img 
                                        className='bottle-cap mx-auto d-block'
                                        src={bottleCap}
                                        alt='code on bottle cap'
                                        width="300px"
                                    />
                            </Accordion.Collapse>
                        </Accordion>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DataTable
                            noHeader={true}
                            keyField="batch" 
                            columns={columns} 
                            data={beerData}
                            defaultSortField="batch" 
                            defaultSortAsc={false}            
                            highlightOnHover
                            progressPending={isLoading} 
                            progressComponent={<Spinner animation="border"/>}
                            pagination 
                            paginationDefaultPage={page}
                            paginationPerPage={rows}
                            paginationComponentOptions={{ 
                            rowsPerPageText: 'Rijen per pagina',
                            rangeSeparatorText: 'van' 
                            }}
                            customStyles={customStyles}
                            onRowClicked={
                            (row) => {
                                history.push(`beers/${row.resourceId}`)
                            }
                            }
                            onChangePage={
                            (page) => setPage(page)
                            }
                            onChangeRowsPerPage={
                            (currentRowsPerPage, currentPage) => {
                                setRows(currentRowsPerPage)
                                setPage(currentPage)
                            }
                            }
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
