import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope} from "@fortawesome/free-solid-svg-icons";
import "./Footer.css";

export default function Footer() {
    return (    
        <footer className="footer">
            <div className="container">       
                <span>
                    &copy; De Machine Bier {new Date().getFullYear()} 
                </span> 
                &nbsp;
                <a href="mailto:info@demachinebier.nl">
                  <FontAwesomeIcon icon={faEnvelope}/>
                </a>  
            </div>
        </footer>
    )
}
