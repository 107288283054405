import React from "react";
import { Form } from "react-bootstrap";

export default function Selector({
    beerData,
    selectedBeer,
    setSelectedBeer
}) {     
    return (
        <Form>
            <Form.Group>
                <Form.Control 
                    as="select"	
                    value={selectedBeer ? selectedBeer.batch : "0"}						
                    onChange={e => {
                        setSelectedBeer(
                            beerData.filter((beer) => beer.batch === parseInt(e.target.value))[0] 
                        )
                    }}
                >
                    {
                        beerData
                            .sort((a,b) => b.batch - a.batch)
                            .map(e => 
                                <option 
                                    key={e.batch} 
                                    value={e.batch}
                                >
                                    {e.batch} - {e.name}
                                </option>)											
                    }
                </Form.Control>
            </Form.Group>
        </Form>	
    )
}