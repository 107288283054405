import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Auth, Hub } from "aws-amplify";
import Header from "./components/Header";
import Routes from "./routing/Routes";
import Footer from "./components/Footer";
import "./App.css";
import "react-datetime/css/react-datetime.css";
import { useDataApi } from "./libs/hooksLib";

function App(props) {
	const [user, setUser] = useState(null);
	const [isAuthenticating, setIsAuthenticating] = useState(true);

	useEffect(() => {
		Hub.listen('auth', ({ payload: { event, data } }) => {
			switch (event) {
			  	case 'signIn':				  
					getUser();		
					break;
			  	case 'signOut':
					setUser(null);
					break;
				default:
					break;
			}
		});
		getUser();
	}, []);

	function getUser() {
		setIsAuthenticating(true)
		return Auth.currentAuthenticatedUser()
			.then(userData => setUser(userData))
			.catch(() => {})
			.then(() => setIsAuthenticating(false));
	  }	

	const [{ data: beerData, isLoading, isError }] = useDataApi('beer', 'get', {
		url: '/beers'
	}, []);	  
	const [selectedBeer, setSelectedBeer] = useState(null)
	useEffect(() => {
		if (beerData) {	
			setSelectedBeer(	
				beerData.sort((a,b) => b.batch - a.batch)[0]
			)			
		}
	}, [beerData])

	return (
		!isAuthenticating && (
			<div className="App">
				<Header
					user={user}
					isError={isError}
				/>
				<main className="container">
					<Routes appProps={{ 
						user, setUser, 
						beerData, 
						isLoading, 
						isError,
						selectedBeer,
						setSelectedBeer
					}} />
				</main>
				<Footer/>
			</div>
		)
	);
}

export default withRouter(App);
