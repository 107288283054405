import React, { useState, useEffect } from "react";
import {} from "react-bootstrap";
import Card from '../components/Beer/Card'
import "./BeerView.css";
import { useParams, useHistory } from "react-router-dom";
function BeerView({ beerData }) {  	

let history = useHistory();
let { id } = useParams()
const [result, setResult] = useState({})

useEffect(() => {
    let searchBeer = beerData.filter((beer) => beer.resourceId === id)
    if (searchBeer.length)
    setResult(searchBeer[0])
    else if (id && beerData.length)
    history.push('/notfound')
    
    }, [beerData, id, history]);
    return (
        <div className="BeerView">
        {
            (Object.keys(result).length !== 0) &&
            <Card data={result}></Card>
        }
        </div>
    )
}
export default BeerView;
