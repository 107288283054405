import React from 'react'
import { Col, Image, } from 'react-bootstrap'

function addDefaultCapSrc(event) {
    event.target.src = process.env.PUBLIC_URL + `/images/caps/unknown.svg`
}

export function getCapColors(data) {
    if (!data)
        data = []
    data = data
        .filter((color) => (!Array.isArray(color) || (color.length)))   // filter empty arrays        
        .map((color) => Array.isArray(color) ? color[0] : color)        // expand nested arrays
        .filter((color) => (color))                                     // filter empty values
    return data.length ? data : ['unknown']
        
}

export function BeerCapCols(props) {
    if (!props.color.length)
        return ''
    const cols = 
        props.color.map((color, index) => 
            <Col key={index} className='col-cap' xs={2}>
                <BeerCap key={index} color={color}></BeerCap>
            </Col>
        );
    return cols;
}

export function BeerCap({
    color
}) {
    return (
        <Image
            className='img-cap'
            src={process.env.PUBLIC_URL + `/images/caps/${color}.svg`}
            alt='cap'
            onError={addDefaultCapSrc}
        />
    )
}