import React from "react";
import { Alert, Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Auth } from "aws-amplify";
import "./Header.css";

export default function Header({
    user,
    isError
}) {

    return (
        <header className='header'>
            <Navbar 
                bg = { user ? "primary" : "dark"}
                variant = "dark"
                expand="lg"
                collapseOnSelect="true"
            >
                <LinkContainer to="/">
                    <Navbar.Brand>
                        De Machine Bier
                    </Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <LinkContainer to="/beers">
                            <Nav.Link>Bieren</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/history">
                            <Nav.Link>Historie</Nav.Link>
                        </LinkContainer>  
                        {user && (            
                            <>            
                                <LinkContainer to="/log">
                                    <Nav.Link>Logs</Nav.Link>
                                </LinkContainer>  
                            </>
                        )}     
                    </Nav>        
                    <Nav className="ml-auto">   
                        {user ? (
                            <>
                                <Nav.Link onClick={() => Auth.signOut()}>Uitloggen</Nav.Link>
                            </>
                        ) : (
                            <>
                                <LinkContainer to="/login">
                                    <Nav.Link>Inloggen</Nav.Link>
                                </LinkContainer>
                            </>
                        )}
                    </Nav>                    
                </Navbar.Collapse>
            </Navbar>
            {isError && (
                <div className='beer-data-error'>
                    <Alert variant='danger'>
                        Bier info kan niet opgehaald worden
                    </Alert>
                </div>
            )}
        </header>
    )
}
