import React from "react";
import { Auth } from "aws-amplify";
import { Button, FormGroup, FormControl, FormLabel, Accordion, Card, Container, Col, Row } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import GoogleButton from 'react-google-button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope} from "@fortawesome/free-solid-svg-icons";
import "./Login.css";

export default function Login(props) {
	const [fields, handleFieldChange] = useFormFields({
		email: "",
		password: ""
	});

	function validateForm() {
		return fields.email.length > 0 && fields.password.length > 0;
	}

	async function handleSubmit(event) {
		event.preventDefault();
		props.setIsLoading(true);

		try {
			await Auth.signIn(fields.email, fields.password);			
      props.history.push('/')
			props.setIsLoading(false);
		} catch (e) {
			alert(e.message);
			props.setIsLoading(false);
		}
	}

	return (
		<div className="Login">			
			<h2>Inloggen</h2>    
			<Container className="pt-4">
				<Row>
					<Col>
						<GoogleButton
							className="google-button"
							label="Inloggen met Google"
							onClick={() => Auth.federatedSignIn({provider: 'Google'})}
						/>
					</Col>
				</Row>
				<Row className="my-2"><Col><hr/>	</Col></Row>
				<Row>
					<Col>
						<Accordion>
							<Card>
								<Card.Header>
									<Accordion.Toggle as={Button} variant="link" eventKey="0">										
										<span>
											<FontAwesomeIcon icon={faEnvelope} size="lg" className="mr-3"/>
										</span>
										Inloggen met email
									</Accordion.Toggle>
								</Card.Header>
								<Accordion.Collapse eventKey="0">
									<Card.Body>							
										<form onSubmit={handleSubmit}>
											<FormGroup controlId="email" bssize="large">
												<FormLabel>Email</FormLabel>
												<FormControl						
													type="email"
													value={fields.email}
													onChange={handleFieldChange}
												/>
											</FormGroup>
											<FormGroup controlId="password" bssize="large">
												<FormLabel>Wachtwoord</FormLabel>
												<FormControl
													type="password"
													value={fields.password}
													onChange={handleFieldChange}
												/>
											</FormGroup>
											<LoaderButton
												block
												type="submit"
												bssize="large"
												isLoading={props.isLoading}
												disabled={!validateForm()}
											>
												Inloggen
											</LoaderButton>
										</form>
									</Card.Body>
								</Accordion.Collapse>
							</Card>
						</Accordion>
					
					</Col>
				</Row>
			</Container>
			  
		</div>
	);
}
