import React from 'react'
import { Card as BsCard, Col, Container, Nav, OverlayTrigger, Popover, Row, Tab, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import EbcIndicator from './EbcIndicator'
import EbuIndicator from './EbuIndicator'
import './Card.css'
import Recipe from './Recipe';
import { getCapColors, BeerCapCols } from './Cap';

function joinIngredients(data) {        
    if (!data)
        return ''

    let summed_and_sorted = Array.from(new Set(data.map(i => i.name)))   // unique names 
        .map(name => {
            return {
                name: name, 
                amount: data
                    .filter(i => i.name === name)   // items with this name
                    .map(i => i.amount)             // get amount
                    .reduce((a,b) => a+b)           // sum amount
                }
            })
            .sort((a,b) => b.amount - a.amount)     // sort by amount

    return summed_and_sorted.map(i => i.name).join(', ')
}

function formatFloat(value, decimals=0) {
    return value ? value.toFixed(decimals) : ''
}

function CardRow(props) {
    return (
        <tr>
            <td>{props.field}</td>
            <td>
                {props.value}
                {props.children}
            </td>
        </tr>
    )
}

function Card(props) {
    let fermentables = joinIngredients(props.data.fermentables)
    let hops = joinIngredients(props.data.hops)
    let miscs = joinIngredients(props.data.miscs)
    let colors = getCapColors(props.data.cap_color)
    let showRecipe = (props.data.batch >= 57)
    
    return (
        <BsCard className='BeerCard'>
            <Tab.Container id="left-tabs-example" defaultActiveKey="info">
                <BsCard.Header>    
                    <Container>
                        <Row>
                            <BeerCapCols color={colors}/>
                            <Col>
                                <BsCard.Title>{props.data.name || <em>N.N.</em>}</BsCard.Title>            
                                <BsCard.Subtitle>{props.data.style}</BsCard.Subtitle>   
                            </Col>
                        </Row>       
                    </Container>             
                </BsCard.Header>
                <BsCard.Body>
                    <Nav variant="pills" defaultActiveKey="info" justify='true'>
                        <Nav.Item>
                            <Nav.Link eventKey="info">Info</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="ingredients">Ingrediënten</Nav.Link>
                        </Nav.Item>
                        {
                            showRecipe && (
                                <Nav.Item>
                                    <Nav.Link eventKey="recipe">Recept</Nav.Link>
                                </Nav.Item>
                            )
                        }
                    </Nav>
                </BsCard.Body>
                <Tab.Content>
                    <Tab.Pane eventKey="info">
                        <Table>                        
                            <tbody>
                                <CardRow field='Batch' value={props.data.batch}></CardRow>
                                <CardRow field='Gebrouwen' value={props.data.brew_date}></CardRow>
                                <CardRow field='Gebotteld' value={props.data.bottle_date}></CardRow>
                                <CardRow field='Alcohol' value={formatFloat(props.data.abv_computed, 1)}>
                                    % vol
                                </CardRow>
                                <CardRow 
                                    field={(<span>
                                        <span>EBC / kleur </span>
                                        <OverlayTrigger placement="top" overlay={
                                            <Popover id="popover-ecb">
                                                <Popover.Content>
                                                    <p>EBC is een maat voor de kleur van bier en is een afkorting van <strong>European Brewery Convention</strong>.</p>
                                                    <p>Waardes lopen van 6-9 voor lichtblond tot 120+ voor zwart bier.</p>
                                                </Popover.Content>
                                            </Popover>
                                        }>
                                            <FontAwesomeIcon icon={faInfoCircle}/>
                                        </OverlayTrigger>
                                    </span>)} 
                                    value={formatFloat(props.data.ebc_kpi)}
                                >
                                    <EbcIndicator value={formatFloat(props.data.ebc_kpi)}/>
                                </CardRow>
                                <CardRow 
                                    field={(<span>
                                        <span>EBU / bitterheid </span>
                                        <OverlayTrigger placement="top" overlay={
                                            <Popover id="popover-ecb">
                                                <Popover.Content>
                                                    <p>De <strong>European Bitterness Unit</strong> (EBU) is een maataanduiding voor de bitterheid van bier.</p>
                                                    <p>Waardes lopen van onder de 20 voor <i>weinig bittere bieren </i> tot 40+ voor <i>zeer bittere bieren</i>.</p>
                                                </Popover.Content>
                                            </Popover>
                                        }>
                                            <FontAwesomeIcon icon={faInfoCircle}/>
                                        </OverlayTrigger>
                                    </span>)} 
                                    value={formatFloat(props.data.ebu_kpi)}
                                >
                                    <EbuIndicator value={formatFloat(props.data.ebu_kpi)}/>
                                </CardRow>
                            </tbody>
                        </Table>
                    </Tab.Pane>
                </Tab.Content>
                <Tab.Content>
                    <Tab.Pane eventKey="ingredients">
                        <Table>                        
                            <tbody>
                                <CardRow field='Vergistbare ingrediënten' value={fermentables}></CardRow>
                                <CardRow field='Hop' value={hops}></CardRow>
                                <CardRow field='Overig' value={miscs}></CardRow>
                            </tbody>
                        </Table>
                    </Tab.Pane>
                </Tab.Content>
                {
                    showRecipe && (
                        <Tab.Content>
                            <Tab.Pane eventKey="recipe">
                                <Recipe data={props.data}></Recipe>
                            </Tab.Pane>
                        </Tab.Content>
                    )
                }
            </Tab.Container>
        </BsCard>
    )
}

export default Card
