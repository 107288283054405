import React from 'react'
import { 
    Table as BsTable
} from 'react-bootstrap'
import './Recipe.css'

// TODO: add numeric rounding to all values
function getRecipeData(data) {
    return {
        grain: (data.fermentables || [])
            .filter(e => e.type === 'grain')
            .sort((a,b) => b.amount - a.amount),
        sugar: (data.fermentables || [])
            .filter(e => e.type === 'sugar')
            .sort((a,b) => b.amount - a.amount),
        mash_steps: (data.mash_steps || [])
            .map(e => { 
                return {
                    name: e.type,
                    duration: e.duration,
                    target: e.temp_target,
                    description: parseInt(e.volume_infusion) 
                        ? `stort ${e.volume_infusion} L op ${e.temp_infusion} °C`
                        : 'via warmtewisselaar'
                }
            }),
        boil_steps: [...new Set((data.hops || []).map(e => e.time))]    // unique boil durations 
            .filter(e => e).sort().reverse()                         
            .map(duration => {
                return {
                    duration: duration,
                    hops: data.hops
                        .filter(e => e.time === duration)
                        .sort((a,b) => b.amount - a.amount)
                }
            })
            .sort((a,b) => b.duration - a.duration),
        yeast: data.yeasts,
        coldhop: (data.hops || []).filter(e => !(e.time)),     // hops with non-positive boil time are assumed to be cold hops
        bottle_sugar: data.bottle_sugar,            
        sg: {
            before_boil: data.sg_before_boil,
            after_boil: data.sg_after_boil
        },
        volume: {
            sparge: data.volume_sparge,
            mash: data.volume_mash,
            before_boil: data.volume_before_boil,
            after_boil: data.volume_after_boil, 
            after_fermentation: data.volume_mandfles,
            loss_bottling: data.equipment.loss_bottling,
            added_during_boil: data.volume_added_during_boil
        },
        time: {
            total_boil: data.total_boil_time
        },
        temperature: {
            fermentation: data.temp_fermentation
        }
    }
}

function Section(props) {
    return (
        <div>
            <h3>{props.title}</h3>
            {props.children}
        </div>
    )
}

function SubSection(props) {
    return (
        <div>
            <h5>{props.title}</h5>
            {props.children}
        </div>
    )
}

function Table(props) {    
    return (
        <BsTable 
            {...props} 
            bordered={true}
            striped={true}
        >
            {
                props.header && (                    
                    <thead>      
                        <tr>
                            {
                                props.header.map(e => <th key={e}>{e}</th>)
                            }
                        </tr>      
                    </thead>
                )
            }{                        
                props.fields && props.data ? (                    
                    <tbody>                
                    {
                        props.data.map((d, i_d) =>                             
                            <tr key={i_d}>
                                {
                                    props.fields.map((f, i_f) =>
                                        <td key={f}> 
                                            {d[f]}
                                            {props.suffix && ` ${props.suffix[i_f]}`}
                                        </td>    
                                    )                                    
                                }
                            </tr>
                        )
                    }
                    </tbody>
                )
                : (
                    <tbody>
                        {props.children}
                    </tbody>
                )
            }
        </BsTable>
    )
}

function Recipe(props) {
    const data = getRecipeData(props.data)
    return (
        <div className="BeerRecipe">
            <Section title='Maischen'>
                <SubSection title='Water'>
                    <Table 
                        fields={['name', 'volume']}
                        suffix={['', ' L']}
                        data={[
                            {name: 'Maischwater', volume: data.volume.mash},
                            {name: 'Spoelwater', volume: data.volume.sparge},
                            {name: 'Totaal', volume: data.volume.mash + data.volume.sparge}
                        ]}
                    />
                </SubSection>
                <SubSection title='Ingrediënten'>
                    <Table 
                        fields={['name', 'amount']}
                        suffix={['', 'kg']}
                        data={data.grain}
                    />
                </SubSection>
                <SubSection title='Stappen'>
                    <Table 
                        fields={['name', 'duration', 'target', 'description']}
                        suffix={['', 'min', '°C', '']}
                        data={data.mash_steps}
                    />
                </SubSection>
            </Section>

            <Section title='Koken'>
                <SubSection title='Info'>
                    <Table 
                        fields={['name', 'value']}
                        data={[
                            {name: 'Totale kooktijd',         value: `${data.time.total_boil} min`},
                            {name: 'Volume voor koken',       value: `${data.volume.before_boil} L`},
                            ...(
                                data.volume.added_during_boil ? 
                                [{name: 'Toevoegen na eiwitschok', value: `${data.volume.added_during_boil} L`}]
                                : []
                            )                            ,
                            {name: 'Volume na koken',         value: `${data.volume.after_boil} L`}
                        ]}
                    />
                </SubSection>
                <SubSection title='Toevoegen'>
                    {
                        (data.sugar.length > 0) && (
                            <Table 
                                header={['Suiker: vanaf aanvang kook', '']}
                                fields={['name', 'amount']}
                                suffix={['', 'kg']}
                                data={data.sugar}
                            />
                        )
                    }
                    {
                        data.boil_steps.map((e, i) =>                             
                            <Table
                                key={i}                  
                                header={[`Hopgift ${i+1}: ${e.duration} min`, '']}                                    
                                fields={['name', 'amount']}
                                suffix={['', 'gram']}
                                data={e.hops}
                            />    
                        )
                    }
                </SubSection>
            </Section>
            <Section title='Vergisten'>
                <SubSection title='Info'>
                    <Table>
                        {
                            data.yeast.map((e, i) =>
                                <tr key={i}>
                                    <td>Gistvat {i+1}</td>
                                    <td>{e.name}</td>
                                </tr>
                            )
                        }
                        <tr>
                            <td>Temperatuur vergisting</td><td>{data.temperature.fermentation} °C</td>
                        </tr>
                    </Table>
                </SubSection>    
                {
                    (data.coldhop.length > 0) && (
                        <SubSection title="Koudhop">                        
                            <Table 
                                    fields={['name', 'amount']}
                                    suffix={['', 'g']}
                                    data={data.coldhop}
                                />

                        </SubSection>
                    )
                }
            </Section>        
            <Section title='Bottelen'>
                <Table 
                    fields={['name', 'value']}
                    data={[
                        {name: 'Bottelsuiker per volume', value: `${data.bottle_sugar} g/L`},
                        {name: 'Gistvat volume',          value: `${data.volume.after_fermentation} L`},
                        {name: 'Bottelverlies',           value: `${data.volume.loss_bottling} L`},
                        {name: 'Totaal bottelsuiker',     value: `${
                            Math.round(data.bottle_sugar * (data.volume.after_fermentation + data.volume.loss_bottling))
                        } g`}
                    ]}
                />
            </Section>
        </div>
    )
}

export default Recipe