import React from 'react'
import './EbuIndicator.css'

function EbuIndicator(props) {
    let text = '';
    if (props.value) {
        // source:  http://www.hobbybrouwen.nl/artikel/hoprend2.html
        let ebu = Math.round(props.value)        
        if (ebu > 40)
            text += 'zeer bitter'
        else if (ebu > 30)
            text += 'bitter'
        else if (ebu > 20)
            text += 'bitterig'
        else
            text += 'weinig bitter'
    }

    return (
        <span> / {text}</span>
    )
}

export default EbuIndicator