import React from "react";
const { ButtonGroup, ToggleButton } = require("react-bootstrap");

export function LogButtonGroup({
    items, 
    value,
    setValue,
    className = '',
    renderItem = (item) => item
}) {
    return (      
        <div className={ "logButtonGroup " + className } >
            <ButtonGroup className="flex-wrap mb-2" toggle size="sm">
                {items.map((item, idx) => (
                    <ToggleButton
                        key={idx}
                        type="radio"
                        variant="outline-secondary"
                        name="radio"
                        value={idx}
                        checked={value === idx}
                        onChange={(e) => 
                            setValue(parseInt(e.currentTarget.value))
                        }
                    >
                        {renderItem(item)}
                    </ToggleButton>
                ))}
            </ButtonGroup>
        </div>
    )
}