export const options = [
    {
        'type': 'Actie', 
        'label': [
            'start brouw', 
            'melkzuur toegevoegd', 
            'suiker toegevoegd', 
            'omgezet', 
            'koudhop toegevoegd',
            'gebotteld',
            'geproefd'
        ],
        'unit': null,
        'valueType': null,
    }, {
        'type': 'Temperatuur',         
        'label': [
            'stamwort'
        ],
        'unit': '°C',
        'valueType': 'number'
    }, {
        'type': 'Volume',       
        'label': [
            'voor kook', 
            'tijdens kook', 
            'na kook',
            'begin vergisting',
            'spoel',
            'inmaischen',
            'opbrengst',
            'zwaarbrouw'
        ],
        'unit': 'Liter',
        'valueType': 'number'
    }, {
        'type': 'SG',     
        'label': [
            'maisch',
            'voor kook', 
            'tijdens kook', 
            'na kook',
            'voor vergisting', 
            'tijdens vergisting', 
            'na vergisting',
            'met bottelsuiker'
        ],
        'unit': 'sg-punt',
        'valueType': 'number'
    }, {
        'type': 'pH',    
        'label': [
            'maisch', 
            'voor kook', 
            'vergisting',
            'proeven'
        ],
        'unit': null,
        'valueType': 'number'
    }, {
        'type': 'Zetmeel',
        'label': [
            'maisch'
        ],
        'unit': null,
        'valueType': 'select',
        'valueItems': ['Volledig', 'Onvolledig']
    }, {
        'type': 'Dop',
        'label': [
            'vat A',
            'vat B',
            'vat C',
            'vat D',
        ],
        'unit': null,
        'valueType': 'cap',
        'valueItems': [
            'blauw',
            'bordeaux',
            'bruin',
            'fuchsia',
            'geel',
            'goud',
            'groen',
            'koper',
            'lichtblauw',
            'lichtgroen',
            'oranje',
            'rood',
            'wit',
            'zilver',
            'zwart'
        ]
    }
];
