import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import historyImg from '../images/history_stove.jpg'

export default function History(){
    return (
        <div className="history">
            <Container>
                <Row>
                    <Col>
                        <h2>Historie</h2>            
                        <p className="lead">
                            Machine houdt men draaiende met olie. Mensen met bier.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={7}><p>
                            De eerste bieren van De Machine Bier worden eind 2014 gebrouwen op de vruchtbare grond van de Haagse Rivierenbuurt. 
                            Gevoed door ons ingenieursbloed bouwen wij (3 Delftse vrienden) in een badkamer een huiveringwekkende brouwinstallatie 
                            op basis van een Ikea Ivar kast, een paar uit de kluiten gewassen wokbranders en wat afgedankte fusten. 
                        </p><p>
                            De hobby slaat aan en het duurt niet lang voordat er een rits temperatuursensoren wordt ingezet om zoveel mogelijk 
                            controle te krijgen over het proces. En dat betaalt zich uit! In de zomer van 2015 wint het gezelschap de Delftse 
                            Locus Publicus amateurbierbrouwwedstrijd voor blond bier met als inzet de “Pamela”: een zware blonde, 
                            fris gehopt en ondanks het vergeten van het korianderzaad verdomde lekker. 
                        </p><p>
                            Met hulp van Ruud van Brouwerij De Eem brouwen we er 900 liter van, die het gehele najaar te proeven is in de Locus Publicus. 
                            Inmiddels is de badkamer verruild voor een custom-made brouwkamer, waar wij op kleine schaal recepten uitdenken en klaarstomen voor het grote publiek.
                        </p>
                    </Col>
                    <Col>
                        <Image src={historyImg} rounded fluid/>
                    </Col>
                    {/* TODO: add brewers pic & brewery pic */}
                </Row>
            </Container>
        </div>
    )
}