import { useState, useEffect, useReducer } from "react";
import { API } from "aws-amplify";

export function useFormFields(initialState) {
	const [fields, setValues] = useState(initialState);

	return [
		fields,
		function(event) {
            console.log(event)
			setValues({
				...fields,
				[event.target.id]: event.target.value
			});
		}
	];
}

const dataFetchReducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_INIT':
            return { 
				...state, 
				...action.request.state,
				isSucces: false,
                isLoading: true, 
				isError: false,            
            };
        case 'FETCH_SUCCESS':
            return {
                ...state,
				isSucces: true,
                isLoading: false,
                isError: false,
                data: action.payload,
            };
        case 'FETCH_FAILURE':
            return {
                ...state,
				isSucces: false,
                isLoading: false,
                isError: true,
            };
        default:
            throw new Error();
    }
};

export function useDataApi(name, verb, initialRequest, initialData) {
    const [request, setRequest] = useState(initialRequest);
    const [state, dispatch] = useReducer(dataFetchReducer, {
		isSucces: false,
        isLoading: false,
        isError: false,
        data: initialData,
    });

    useEffect(() => {
        let didCancel = false;

        const fetchData = async () => {
            dispatch({ type: 'FETCH_INIT', request: request });
            API[verb](name, request.url, request.init)
                .then((response) => {
                    if (!didCancel) {
                        dispatch({ type: 'FETCH_SUCCESS', payload: response });
                    }
                })
                .catch((error) => {
                    if (!didCancel) {
                        dispatch({ type: 'FETCH_FAILURE' });
                    }
                })
        };
        request && fetchData();
        return () => {
            didCancel = true;
        };
    }, [request, name, verb]);

    return [state, setRequest];
};