import React from "react";
import "./Home.css";
import logo from '../images/Logo_zwartwit.svg'
import { Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

function Home(props) {
	let history = useHistory();
	
	return (
		<div className="Home">
            <Row className="mt-5">
                <Col>
					<img
						className="logo"
						src={logo}
						alt='De Machine Bier'
					/>
				</Col>
			</Row>
            <Row className="mt-4">
				<Col>  
             		<p className="lead">
                	    <strong>Machines</strong> houdt men draaiende met olie. Mensen met <strong>bier</strong>.
                	</p>
             		<p>
                	    Welkom op de site van amateurbrouwerij De Machine Bier.
                	</p>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>    
					<Button 
						className="mx-2" 
						size="lg" 
						variant="secondary" 
						onClick={() => history.push('/history')}
					>
						Ons verhaal
					</Button>
					<Button 
						className="mx-2" 
						size="lg" 
						variant="dark" 
						onClick={() => history.push('/beers')}
					>
						Bier info
					</Button>
                </Col>
            </Row>
		</div>
	);
}

export default Home;
